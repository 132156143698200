(function ($) {
  Drupal.behaviors.ie11UpgradeV1 = {
    attach: function (context) {
      var $ieUpgradeContainer = $('.js-ie-upgrade-v1', context);
      var $ieUpgradeClose = $('.js-ie-upgrade-close', $ieUpgradeContainer);

      if ($.cookie('CLINIQUE_IE_UPGRADE_POPUP')) {
        return;
      }
      /* Colorbox does not work on less than or equal IE11
       * Display IE Upgrade Popup using basic function: append to the body.
       */
      if (navigator.userAgent.indexOf('MSIE') >= 0 || navigator.appVersion.indexOf('Trident/') > -1) {
        $ieUpgradeContainer.removeClass('hidden');
        $ieUpgradeClose.on('click', function() {
          $.cookie('CLINIQUE_IE_UPGRADE_POPUP', '1', { path: '/' });
          $ieUpgradeContainer.addClass('hidden');
        });
      } else {
        $ieUpgradeContainer.remove();
      }
    }
  };
})(jQuery);
